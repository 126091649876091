import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Школа англійської Evergreen
			</title>
			<meta name={"description"} content={"Школа англійської Evergreen - професійні заняття з англійської мови для всіх рівнів. Поліпшіть свої знання англійської з нами."} />
			<meta property={"og:title"} content={"Школа англійської Evergreen"} />
			<meta property={"og:description"} content={"Школа англійської Evergreen - професійні заняття з англійської мови для всіх рівнів. Поліпшіть свої знання англійської з нами."} />
			<meta property={"og:image"} content={"https://vorix-caita.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vorix-caita.com/img/325356456.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vorix-caita.com/img/325356456.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vorix-caita.com/img/325356456.png"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});